/* CircularStd @font-face kit */
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-Black.woff2'), url('CircularStd-Black.eot'),
		url('CircularStd-Black.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-Black.woff') format('woff'), url('CircularStd-Black.ttf') format('truetype'),
		url('CircularStd-Black.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-BlackItalic.woff2'),
		url('CircularStd-BlackItalic.eot'),
		url('CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-BlackItalic.woff') format('woff'),
		url('CircularStd-BlackItalic.ttf') format('truetype'),
		url('CircularStd-BlackItalic.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 800;
	font-style: italic;
}
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-Bold.woff2'), url('CircularStd-Bold.eot'),
		url('CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-Bold.woff') format('woff'), url('CircularStd-Bold.ttf') format('truetype'),
		url('CircularStd-Bold.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-BoldItalic.woff2'),
		url('CircularStd-BoldItalic.eot'),
		url('CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-BoldItalic.woff') format('woff'),
		url('CircularStd-BoldItalic.ttf') format('truetype'),
		url('CircularStd-BoldItalic.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-Medium.woff2'), url('CircularStd-Medium.eot'),
		url('CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-Medium.woff') format('woff'), url('CircularStd-Medium.ttf') format('truetype'),
		url('CircularStd-Medium.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-MediumItalic.woff2'),
		url('CircularStd-MediumItalic.eot'),
		url('CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-MediumItalic.woff') format('woff'),
		url('CircularStd-MediumItalic.ttf') format('truetype'),
		url('CircularStd-MediumItalic.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-Book.woff2'), url('CircularStd-Book.eot'),
		url('CircularStd-Book.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-Book.woff') format('woff'), url('CircularStd-Book.ttf') format('truetype'),
		url('CircularStd-Book.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'CircularStd';
	font-display: swap;
	src: local('Circular font'), url('CircularStd-BookItalic.woff2'),
		url('CircularStd-BookItalic.eot'),
		url('CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
		url('CircularStd-BookItalic.woff') format('woff'),
		url('CircularStd-BookItalic.ttf') format('truetype'),
		url('CircularStd-BookItalic.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 400;
	font-style: italic;
}
